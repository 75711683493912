<template>
  <div class="popup-container" :class="{'is-active': modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-educourse-e01">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <div class="course-summary">
              <article class="course-card">
<!--                <div class="course-image">-->
<!--                  <a href="javascript:" class="image-link"></a>-->
<!--                  <figure class="image-figure">-->
<!--                    <img v-if="params.thumb" :src="getImg(params.thumb)" alt="">-->
<!--                    <img v-else-if="params.mstThumb" :src="getImg(params.mstThumb)" alt="">-->
<!--                    <CourseThumb v-else :num="params.crseMstSn"/>-->
<!--                  </figure>-->
<!--                </div>-->
                <div class="course-content">
                  <div class="content-header">
                    <h4 class="title"><a href="javascript:" class="title-link">[필수] 개인(신용)정보 수집ㆍ이용 동의서(임직원 연수용)</a></h4>
                  </div>
                </div>
              </article>
            </div>
            <div class="course-enrollments">
              <CourseConsent
                  v-model="checked"
                  :consents="consentsCI"
                  :is-view="isView"
              />
            </div>
          </div>
          <div class="kb-form-bottomtext">
            <div class="kb-table-bottom" style="text-align: right">
              <div><strong class="text">{{ currentDateStr }} 성명 : <input v-model="param.text1" class="kb-form-control reg_text" id="" required="" type="text" placeholder="" data-parsley-id="1" style="font-size:0.9em; padding-right:0px;width:70px;display:inline-block;">
                </strong></div>
            </div>
          </div>

          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">닫기</span></button>
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickCIAgreement"><span class="text">동의완료</span>
            </button>
          </div>
          <div class="kb-form-bottomtext">
            <div class="kb-table-bottom" style="text-align: left;">
              <div><strong class="text">※ 본 동의는 HR부(인재개발) 포인트리 지급 목적에 한해 사용됩니다.
              </strong></div>
            </div>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>

<script>

import {reactive, ref} from "vue";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useStore} from "vuex";
const {showConfirm, showMessage} = useAlert();
import {ACT_INSERT_AGREEMENT_CI} from "@/store/modules/live/live";
import CourseConsent from '@/components/common/CourseConsent';
import {timestampToDateFormat} from "@/assets/js/util";

export default {
  name: 'CIAgreementModal',
  components: {CourseConsent},
  props: {
    modelValue: Boolean,
    distCrseSn: String,
    lrnerId: String,
    lrnerNm: String
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {

    const store = useStore();
    const checked = ref([]);
    const consentsCI = ['ci1', 'ci2'];
    const isView = ref(false);

    const currentDateStr = timestampToDateFormat(new Date(), 'full-kr');

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const param = reactive({
      text1: '',
      text2: ''
    });

    const clickCIAgreement = () => {
      if (param.text1 === store.state.auth.session.lrnerNm.trim() && checked.value.length === 1) {
        showConfirm({
          text: '동의서를 등록하시겠습니까?',
          callback: saveCIAgreement
        })
      } else if(checked.value.length < 1) {
        showMessage("동의여부를 확인해주시기 바랍니다.<br> (미동의 시 포인트리 지급이 제한됩니다.) ");
      } else if (param.text1 === "") {
        showMessage("성명을 입력 바랍니다.");
      } else {
        showMessage("로그인한 직원의 성명과 일치하지 않습니다.");
      }
    };

    const saveCIAgreement = () => {
      store.dispatch(`live/${ACT_INSERT_AGREEMENT_CI}`, {
        lrnerId: props.lrnerId,
        lrnerNm: props.lrnerNm
      }).then(res=> {
        if(res.status && res.status.code == 200) {
          showMessage({
            text: 'CI 동의서 작성을 완료했습니다.',
            okBtnTitle: '확인',
            callback: () => {
              closeModal();
            },
          });
        } else {
          showMessage('CI 동의서 작성을 실패했습니다.<br/>관리자에게 문의바랍니다.');
        }
      }).catch(e => {
        console.error(e);
      });
    }

    return {
      closeModal,
      clickCIAgreement,
      param,
      checked,
      consentsCI,
      isView,
      currentDateStr
    }
  }
};
</script>