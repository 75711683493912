<template>
  <div :class="myClassName">
    <MyImage v-if="isMobile"/>
    <h4 class="title">{{ session.lrnerNm }}님, 반가워요!</h4>
    <div class="meta">
      <span class="meta-text">{{ session.jbgdNm }} {{ session.jbpsNm }}</span>
      <span class="meta-text">{{ session.deptNm }}</span>
    </div>
  </div>

  <div v-if="isMobile" class="my-links my-links-block">
    <template v-if="isKbBank && !session.vice">
      <router-link :to="{name:'MyAbility'}" class="link kb-btn kb-btn-outline kb-btn-content-item">
        <span class="text">역량개발평가</span>
      </router-link>
    </template>

    <template v-if="isKbBank">
      <router-link :to="{name:'MyPageMain'}" class="link kb-btn kb-btn-outline kb-btn-content-item">
        <span class="text">마이페이지</span>
      </router-link>
      <router-link :to="{name:'MyTalentBoard'}" class="link kb-btn kb-btn-outline kb-btn-content-item">
        <span class="text">Talent Board</span>
      </router-link>
      <router-link v-if="session.vice" :to="{name:'MyViceTalentBoard'}" class="link kb-btn kb-btn-outline kb-btn-content-item">
        <span class="text">Talent Coaching Board</span>
      </router-link>
    </template>
    <router-link :to="{name:'HotTipMain'}" class="link kb-btn kb-btn-outline kb-btn-content-item">
      <span class="text">Hot Tip[핫;팁]</span>
    </router-link>
  </div>
  <div v-else class="my-links">
    <template v-if="isKbBank && !session.vice">
      <router-link :to="{name:'MyAbility'}" class="link">
        <span class="link-text">역량개발평가</span><i class="icon-arrow"></i>
      </router-link>
    </template>

    <template v-if="isKbBank">
      <router-link :to="{name:'MyPageMain'}" class="link">
        <span class="link-text">마이페이지</span><i class="icon-arrow"></i>
      </router-link>
      <router-link :to="{name:'MyTalentBoard'}" class="link">
        <span class="link-text">Talent Board</span><i class="icon-arrow"></i>
      </router-link>
      <router-link v-if="session.vice" :to="{name:'MyViceTalentBoard'}" class="link">
        <span class="link-text">Talent Coaching Board</span><i class="icon-arrow"></i>
      </router-link>
      <a href="javascript:" v-if="session.vice" @click="goToAdmin" class="link">
        <span class="link-text">우리부점 학습현황</span><i class="icon-arrow"></i>
      </a>
      <a href="javascript:" v-if="session.trnMngr" @click="goToAdmin" class="link">
        <span class="link-text">부점 연수관리자</span><i class="icon-arrow"></i>
      </a>
    </template>
  </div>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";
import MyImage from "@/components/main/MyImage";
import navigationUtils from "@/assets/js/navigationUtils";
import {ACT_GET_ADMIN_AUTH, TOKEN_ADMKEY} from "@/store/modules/auth/auth";
import {isSuccess} from "@/assets/js/util";
import {saveToken} from "@/assets/js/localstorage.service";

export default {
  name: "MySection",
  components: {MyImage},
  props: {
    myClassName: String,
    isKbBank: Boolean
  },
  setup() {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const isMobile = navigationUtils.any();

    const goToAdmin = () => {

      store.dispatch(`auth/${ACT_GET_ADMIN_AUTH}`).then(res => {
        if( isSuccess(res) && res.adminToken) {
          saveToken(TOKEN_ADMKEY , res.adminToken)
          const windowOption = 'scrollbars=0,resizable=0,top=0,left=' + window.screenLeft + ',width=1800,height=900'
          const popUpObj = window.open( `${process.env.VUE_APP_ADMIN_URL}modal/sso?sso=${res.sso}` , '', windowOption);
          if (popUpObj === null || popUpObj === undefined){
            alert('차단된 팝업창을 허용해 주십시오.');
          }else{
            popUpObj.focus();
          }


        } else {
          alert('차단된 팝업창을 허용해 주십시오.')
        }
      }).catch(e => {
        console.error(e)
        alert('관리자 페이지로 이동할 수 없습니다.')
      })
      //
      // const isVice = session.value.vice ? 'Y' : 'N';
      // store.dispatch(`auth/${ACT_SSO_AUTH_ADMIN}`,isVice).then(res => {
      //   if(res.sso){
      //     const windowOption = 'scrollbars=0,resizable=0,top=0,left=' + window.screenLeft + ',width=1800,height=900'
      //     const popUpObj = window.open( `${process.env.VUE_APP_ADMIN_URL}modal/sso?sso=${res.sso}` , '', windowOption);
      //     if (popUpObj === null || popUpObj === undefined){
      //       alert('차단된 팝업창을 허용해 주십시오.');
      //     }else{
      //       popUpObj.focus();
      //     }
      //   }
      // }).catch(e => {
      //   console.error(e);
      // });
      //

    }

    return{
      session,
      isMobile,
      goToAdmin,
    }
  }
}
</script>