<template>
  <ul class="square-list">
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <!--        <router-link  :to="{name: 'GroupLearning'}" class="square-link"></router-link>-->
        <router-link  :to="'prep/hottip/main'"  class="square-link"></router-link>
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_07.png`)"   alt=""></div>
        <div class="square-content"><h4 class="title">Hot Tip[핫;팁]</h4></div>
      </div>
    </li>
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <!--        <a href="javascript:" class="square-link" @click="underConstruction" v-if="sslMenu.indexOf('ssl/home') > 0"></a>-->
        <router-link :to="`${sslMenu}`" class="square-link"></router-link>
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_01.png`)" alt=""></div>
        <div class="square-content"><h4 class="title">쓸</h4></div>
      </div>
    </li>
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <router-link :to="'prep/hrdcloud'" class="square-link"></router-link>
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_02.png`)" alt=""></div>
        <div class="square-content"><h4 class="title">HRD 클라우드</h4></div>
      </div>
    </li>
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <router-link :to="'train/regular'" class="square-link"></router-link>
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_03.png`)"   alt=""></div>
        <div class="square-content"><h4 class="title">판매자격연수</h4></div>
      </div>
    </li>
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <!--        <router-link :to="{name : 'TrainProceeding', query: {classCateSn: 42}}" class="square-link"></router-link>-->
        <router-link :to="'dept/duty-lrn-stt'" class="square-link"></router-link>
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_04.png`)" alt=""></div>
        <div class="square-content"><h4 class="title">통합법정교육</h4></div>
      </div>
    </li>
    <!-- square-item -->
<!--    <li class="square-item">-->
<!--      <div class="square">-->
<!--        <router-link  :to="{name: 'GroupLearning'}" class="square-link"></router-link>-->
<!--        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_05.png`)"   alt=""></div>-->
<!--        <div class="square-content"><h4 class="title">그룹러닝</h4></div>-->
<!--      </div>-->
<!--    </li>-->
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <a href="javascript:" class="square-link" @click="chkCIAgreeModal"></a>
<!--        <router-link :to="{name: 'MyAbility'}" class="square-link"></router-link>-->
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_06.png`)"  alt=""></div>
        <div class="square-content"><h4 class="title">연계정보(CI) 동의</h4></div>
      </div>
    </li>
  </ul>
  <CIAgreementModal
      v-if="ciAgreeModal"
      v-model="ciAgreeModal"
  />
</template>

<script>
import {ref} from 'vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import {customPageMove, moveSSL} from '@/assets/js/modules/common/common';
import {useStore} from 'vuex';
import {ACT_GET_MENU} from '@/store/modules/menu/menu';
import {getItems, lengthCheck} from '@/assets/js/util';
import CIAgreementModal from "@/components/ciagreement/CIAgreementModal.vue";
import navigationUtils from "@/assets/js/navigationUtils";
import {useRouter} from "vue-router";
import {CHK_AGREEMENT_CI} from "@/store/modules/live/live";


export default {
  name: "SquareList",
  components: {CIAgreementModal},
  setup() {

    const {showMessage} = useAlert();
    const store = useStore();
    const ciAgreeModal = ref(false);
    const router = useRouter();

    // 2022-11-22 : 2023년 3월 오픈 [김보희 대리 요청]
    const underConstruction = () => {
      return showMessage('학습기간이 아닙니다.');
    }

    const chkCIAgreeModal = () => {
      store.dispatch(`live/${CHK_AGREEMENT_CI}`, {
      }).then(res=> {
        if(res.status && res.result.number == 200) {
          if (navigationUtils.any()) {
            router.push({name: 'CIAgreement'});
          } else {
            ciAgreeModal.value = true;
          }
        } else if(res.status && res.result.number == 401) {
          showMessage('이미 동의서 작성 이력이 존재합니다.');
        } else {
          showMessage('서버 문제가 발생했습니다. 관리자에게 문의하세요.');
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const sslMenu = ref('');

    const getMenu = () => {
      store.dispatch(`menu/${ACT_GET_MENU}`).then(res=>{
        if(lengthCheck(res)) {
          const items = getItems(res).filter(x => x.menuSn === 9);

          items.forEach((item) => {
            // sslMenu.value = `/app${item.children.filter(y => y.menuNm === 'SSL')[0].menuUrl}`;
            sslMenu.value = `${item.children.filter(y => y.menuNm === 'SSL')[0].menuUrl}`;
          })
        }


      })
    }

    getMenu();

    return {
      underConstruction,
      customPageMove,
      moveSSL,
      sslMenu,
      chkCIAgreeModal,
      ciAgreeModal
    }

  }
}
</script>
