<template>
  <div class="my-obj">
    <img class="obj" :src="`${imgArray[getRandom(0,4)].img}`" :alt="imgArray[getRandom(0,4)].alt" />
  </div>
</template>

<script>
import {ref} from "vue";
import {getRandom} from "@/assets/js/util";

export default {
  name: "MyImage",
  setup() {
    const imgArray = ref([
      {img: require('../../assets/lxp/images/main/sticker_ago.svg'), alt: '포스 아거'},
      {img: require('../../assets/lxp/images/main/sticker_kiki.svg'), alt: '루나 키키'},
      {img: require('../../assets/lxp/images/main/sticker_bb.svg'), alt: '심쿵 비비'},
      {img: require('../../assets/lxp/images/main/sticker_lamu.svg'), alt: '롤로 라무'},
      {img: require('../../assets/lxp/images/main/sticker_kolly.svg'), alt: '멜랑 콜리'},
    ]);

    return {
      imgArray,
      getRandom
    }
  }
}
</script>